li.menu-item {
   margin: 0 0 10px 0;
}
.jms-p5{
    padding:7px !important;
}
.jms-m0{
    margin: 0px !important ;
}
.f-white{
    color: white !important;
}
.align-center{
    text-align: center;
}
.jms-instruction {
    
    width: 100%;
 }
 
 .jms-container {
    width: 100%;
    /* Can be in percentage also. */
    height: auto;
    margin: 0 auto;
    // padding: 10px;
    position: relative;
 }
 
 .exam-title {
    font-weight: 400;
    color: #444444;
    text-transform: capitalize;
    font-size: 12px;
 
 }
 
 .exam-label {
    font-weight: 700;
    color: #4e99c5;
    text-transform: uppercase;
 }
 
 .exam-total-duration-position-right {
    position: absolute;
    right: 15px;
    font-size: 10px;
    padding: 10px 0px 10px 0px;
 }
 
 .exam-total-duration-position-left {
    position: absolute;
    left: 15px;
    font-size: 10px;
    padding: 10px 0px 10px 0px;
 }
 
 .exam-question-chooser {
    background-color: #e2e5e8;
    border-radius: 25px;
    width: 30px;
    height: 30px;
    padding: 5px;
 }
 
 .exam-selected {
    // background-color: #78b0d1 !important;
    color: #ffffff !important;
 
    border: 1px solid black;
 }
 
 .exam-answered {
 
    background-color: #36be74 !important;
 }
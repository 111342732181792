.fg-body {
  position: relative;
}

body {
/*  -webkit-transform-style: preserve-3d;
   -webkit-backface-visibility: hidden;*/
}
.fg-card {
  position: absolute;
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  display: block;
  border: none;
  opacity: 1;
}

.fg-card.animate {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.fg-card.active {
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.fg-card.active.top {
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.fg-card.active.left {
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.fg-card.active.right {
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.fg-card img{
  display: block;
}

.fg-card.fg-flipping {
  -o-transform: rotateX(180deg) !important;
  -webkit-transform: rotateX(180deg) !important;
  -moz-transform: rotateX(180deg) !important;
  transform: rotateX(180deg) !important;
  opacity: 0;
}

.fg-card.fg-flipping.top {
  -o-transform: rotateX(-180deg) !important;
  -webkit-transform: rotateX(-180deg) !important;
  -moz-transform: rotateX(-180deg) !important;
  transform: rotateX(-180deg) !important;
  opacity: 0;
}

.fg-card.fg-flipping.left {
  -o-transform: rotateY(-180deg) !important;
  -webkit-transform: rotateY(-180deg) !important;
  -moz-transform: rotateY(-180deg) !important;
  transform: rotateY(-180deg) !important;
  opacity: 0;
}

.fg-card.fg-flipping.right {
  -o-transform: rotateY(180deg) !important;
  -webkit-transform: rotateY(180deg) !important;
  -moz-transform: rotateY(180deg) !important;
  transform: rotateY(180deg) !important;
  opacity: 0;
}

.fg-caption {
  position: absolute;
  width: 100%;
  text-align: center;
  margin: 10px 0;
  top: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s ease-in-out;
}
.jms-image-view{
    background-size: cover;
    //backgroundImage: `url(${image.ext != "png" && image.ext != "jpg" && image.ext != "jpeg" ? pdfIcon : image.src})`,
    width: -webkit-fill-available;
    border-radius: 5px;
    border: 3px solid #e8e7e7;
    // height: 130 !important;
    padding:26px;

}
.jms-file-view{
    background-size: cover;
    //backgroundImage: `url(${image.src})`, 
    // backgroundImage: `url(${image.ext == "pdf" ? pdfIcon : docIcon})`,

    width: -webkit-fill-available;
    border-radius: 5px;
    border: 3px solid #e8e7e7;
    // height: 130 !important;
    padding:26px;
    display: flex;
}
.jms-img-tag{
    border-radius: 5px;
    border: 3px solid #e8e7e7
}
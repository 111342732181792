.pointer {
    color: #00B0FF;
    font-family: 'Pacifico';
    font-size: 24px;
    margin-top: 15px;
    position: absolute;
    top: 130px;
    right: -40px;
}

.fg-body {
       top: -17px;
}


.pointer2 {
    color: #00B0FF;
    font-family: 'Pacifico';
    font-size: 24px;
    margin-top: 15px;
    position: absolute;
    top: 130px;
    left: -40px;
}

pre {
    margin: 80px auto;
}

pre code {
    padding: 35px;
    border-radius: 5px;
    font-size: 15px;
    background: rgba(0, 0, 0, 0.1);
    border: rgba(0, 0, 0, 0.05) 5px solid;
    max-width: 500px;
}


.main {
    float: left;
    width: 100%;
    margin: 0 auto;
}


.main h1 {
    padding: 20px 50px 10px;
    float: left;
    width: 100%;
    font-size: 60px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-weight: 100;
    margin: 0;
    padding-top: 25px;
    font-family: 'Noto Serif';
    font-style: italic;
    letter-spacing: 2px;
    text-transform: capitalize;
}

.main h1.demo1 {
    background: #1ABC9C;
}

.reload.bell {
    font-size: 12px;
    padding: 20px;
    width: 45px;
    text-align: center;
    height: 47px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
}

.reload.bell #notification {
    font-size: 25px;
    line-height: 140%;
}

/* .reload,
.btn {
    display: inline-block;
    border: 4px solid #A2261E;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background: #CC3126;
    display: inline-block;
    line-height: 100%;
    padding: 0.7em;
    text-decoration: none;
    color: #0D2633;
    width: 100px;
    line-height: 140%;
    font-size: 17px;
    font-family: open sans;
    font-weight: bold;
} */

.reload:hover {
    background: #A2261E;
}

/* .btn {
    width: 200px;
    color: #0D2633;
    border: none;
    margin-left: 10px;
    background: #00B0FF;
} */

.clear {
    width: auto;
}

/* .btn:hover,
.btn:hover {
    background: #00CDFF;
} */

/* .btns {
    width: 410px;
    margin: 50px auto;
} */

.credit {
    font-family: "noto serif";
    font-style: italic;
    text-align: center;
    color: #3D5455;
    color: rgba(255, 255, 255, 0.35);
    padding: 10px;
    margin: 0 0 40px 0;
    float: left;
    width: 100%;
    letter-spacing: 1px;
}

.credit a {
    text-decoration: none;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.55);
}

.back {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    display: block;
    padding: 7px;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: rgba(0, 0, 0, 0.25);
    font-weight: bold;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.5);
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
}

.back:hover {
    background: rgba(0, 0, 0, 0.5);
}


.page-container {

    margin: auto;
    position: relative;
}

.page-container p {
    font-size: 21px;
    font-weight: 100;
    line-height: 180%;
}

.page-container h3 {
    font-family: "noto serif";
    font-size: 23px;
}

.gallery {
    /*  width: 500px;*/
    height: 333px;
    margin: 150px auto 100px;
}

.navigation {
    margin-bottom: 150px;
}

.fg-card,
.fg-card > img {
    border-radius: 20px !important;
}

.fg-caption {
    color: white;
    font-style: italic;
    font-family: noto serif;
    font-size: 24px;
}

/*  body .one .bsa_it_ad { background: transparent; border: none; font-family: inherit; padding: 0 15px 0 10px; margin: 0; text-align: center; }
body .one .bsa_it_ad:hover img { -moz-box-shadow: 0 0 3px #000; -webkit-box-shadow: 0 0 3px #000; box-shadow: 0 0 3px #000; }
body .one .bsa_it_ad .bsa_it_i { display: block; padding: 0; float: none; margin: 0 0 5px; }
body .one .bsa_it_ad .bsa_it_i img { padding: 0; border: none; }
body .one .bsa_it_ad .bsa_it_t { padding: 6px 0; }
body .one .bsa_it_ad .bsa_it_d { padding: 0; font-size: 12px; color: #333; }
body .one .bsa_it_p { display: none; }
body #bsap_aplink, body #bsap_aplink:hover { display: block; font-size: 10px; margin: 12px 15px 0; text-align: center; }*/

/*.ads {
  position: fixed;
  left: 10px;
  bottom: 10px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  z-index: 99;
  text-align: center;
  max-width: 200px;
}

.ads a {
  color: black;
}

.ad-header {
  margin: 0 0 -2px 0px;
  background: white;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}
.ad-header a {
  float: right;
  border-left: 1px solid #f3f3f3;
  padding:10px;
  line-height: 100%;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: black;
}
.ad-header a:hover {
  background: #eee;
}

.ads .promo {
  position: absolute;
  top: -195px;
  left: 0;
}*/

.message {
    max-width: 550px;
    margin: 50px auto 0;
    padding: 14px;
    background: #72EC80;
    color: #0E530E;
    font-weight: normal;
    font-style: normal;
    border-radius: 3px;
}

.success-message a {
    color: black;
    border-bottom: 1px dotted #000;
}

.success-message {
    background: #72EC80;
    color: #0E530E;
}

.error-message a {
    color: #FFF;
    border-bottom: 1px dotted #fff;
}

.error-message {
    background: #EC7272;
    color: #FFF;
}

.socials {
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    float: left;
    padding: 9px 5px 5px;
    margin: 0 0 60px 0;
}

.socials > * {
    display: inline !important;
}

.socials .fb-like {
    top: -5px;
    min-width: 85px;
    text-align: left;
}

.socials .fb-like > span {
    vertical-align: top;
}

.socials .twitter-share-button {
    width: 85px !important;
}

#carbonads {
    --width: 180px;
    --font-size: 14px;
}

#carbonads {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
    max-width: var(--width);
    border-radius: 4px;
    text-align: center;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, .1);
    background-color: hsl(0, 0%, 98%);
    font-size: var(--font-size);
    line-height: 1.5;
}

#carbonads a {
    color: inherit;
    text-decoration: none;
}

#carbonads a:hover {
    color: inherit;
}

#carbonads span {
    position: relative;
    display: block;
    overflow: hidden;
}

.carbon-img {
    display: block;
    margin-bottom: 8px;
    max-width: var(--width);
    line-height: 1;
}

.carbon-img img {
    display: block;
    margin: 0 auto;
    max-width: var(--width) !important;
    width: var(--width);
    height: auto;
}

.carbon-text {
    display: block;
    padding: 0 1em 8px;
}

.carbon-poweredby {
    display: block;
    padding: 10px var(--font-size);
    background: repeating-linear-gradient(-45deg, transparent, transparent 5px, hsla(0, 0%, 0%, .025) 5px, hsla(0, 0%, 0%, .025) 10px) hsla(203, 11%, 95%, .4);
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 600;
    font-size: 9px;
    line-height: 0;
}

@media only screen and (min-width: 320px) and (max-width: 759px) {
    #carbonads {
        float: none;
        margin: 0 auto;
        max-width: 330px;
    }

    #carbonads span {
        position: relative;
    }

    #carbonads > span {
        max-width: none;
    }

    .carbon-img {
        float: left;
        margin: 0;
    }

    .carbon-img img {
        max-width: 130px !important;
    }

    .carbon-text {
        float: left;
        margin-bottom: 0;
        padding: 8px 20px;
        text-align: left;
        max-width: calc(100% - 130px - 3em);
    }

    .carbon-poweredby {
        left: 130px;
        bottom: 0;
        display: block;
        width: 100%;
    }
}

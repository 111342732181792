.react-tel-input .form-control{
    width: 186px !important;
    font-size: 13px !important;
    padding-left: 42px !important;
    padding-right: 0px !important;
    height: 38px !important;
    border-radius: 0.25rem 0px 0px 0.25rem !important;
}
.jms-h-100{
    height: auto !important;
}
.jms-feedback-card{
    padding: 25px 25px 25px;
    margin-bottom: 20px;
    background-color: aliceblue;
}
.jms-feedback-card-col{
    border-top: 1px solid #dedede;
    padding: 13px;
    height: 120px;
    overflow: auto;
}
.jms-feedback-card-col-2{
    border-top: 1px solid #dedede;
    padding: 13px 13px 0px 13px;
}
.jms-feedback-author{
    max-width: 280px;
    float: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0px;
}
.jms-feedback-subject{
    font-weight: 700;
    color: #1a739c ;
    padding-bottom: 5px;
    width: 350px;
    float: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0px;
}
.app-theme-white.app-container{
background-color: #f2f7fd!important;
}

/* custom css */

.header-link{
    font-weight: 600 !important;
    font-size: 1rem !important;
    font-family: 'Montserrat', sans-serif;
  }
  .signup-link{
    font-size: 17px;
    font-weight: bold;
    font-family: open sans;
  }
  
  .button-trial{
    color: #fff;
    text-decoration: none;
  }
  .button-trial:hover{
    color: #fff;
    text-decoration: none;
  }
  section.features-area {
    background-color: #edf4fc;
  }
  section.features-area .feature-tale {
    z-index: 0 !important; 
  }
section.banner-section .banner-left h1, section.banner-section .banner-left .buttonz{
    font-family: 'Montserrat', sans-serif;
}

.dropdown-menu{
    min-width: 10rem;
    background: #f2f7fd;
}
.dropdown-menu::before, .dropdown-menu::after {
    display: none;
}
.subscribe-toast{   
    color: #ab2d2d;
    background: #f3d1d1;
    border: none;
    float: right;
}
.subscribe-toast:hover{   
    color: #ab2d2d;
    background: #f7caca;
    border: none;
    float: left;
}
section.subscription-area .sub-area .subscribe_form .input-group span button {
    z-index: 3;
}
.sucess-toast {
    color: #104212;
    background: #93de8a;
    border: none;
    float: left;
}
.sucess-toast:hover {
    color: #104212;
    background: #93de8a;
    border: none;
    float: left;
}
.f-15{
  font-size: 14px;
}
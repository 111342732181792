.jms-update-row{
    padding:7px 7px 0px 7px;
    margin: 7px 7px 7px 7px;
    
color: #205fd6;
}
.jms-update-row-bullet{
    background: #d9f7f1;
    border-radius: 6px;
    padding: 22px;
    margin: 7px 7px 7px 7px;
}
.jms-update-bullot-font{
    color: #236446;
}
.modal-header{
    background: #becdec !important
}
.jms-basic:hover{
background-color: #576896 !important;
}
.jms-silver:hover{
    background-color: #576896 !important;

    }
 .jms-gold:hover{
background-color: #576896 !important;
    
 }
 .jms-platinum:hover{
     background-color: #576896 !important;
        
 }
 .jms-hdr{
    border-color: #eb2cc1 transparent transparent transparent !important
 }
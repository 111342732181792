$primary-color: 'red';
// How you import this will depend heavily on your build setup 
// If using webpack though, `~` refers to node_modules 
// @import '~loaders.css/src/animations/line-scale.scss' 
@import 'loaders.css/src/animations/line-scale.scss';
 
.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}
.ball-clip-rotate > div {
    border: 2px solid white!important;
}
.loader-align{
    margin: auto;
    position: relative;
    top: 4px;
}
.jsm-start-class-div{
  // position: absolute;
    color: #3be2c1;
    text-align: center;
    padding-top: 0px;
    font-size: larger;
    font-weight: 600;
}
.jms-font-icon-lg{
  text-align: center;
    font-size: 74px;
}
iframe{
    width: 100%;
    height: 342px;
}
.jms-span-video-details{
    position: absolute;
    float: right;
    padding: 7px;
    bottom: 19px;
    right: 4px;
    color: gray;
}
 /* .player-wrapper {
    border-bottom: 2px solid #62e5c8;
  } */
  .player-wrapper div  {
    /* border-bottom: 2px solid #62e5c8; */
    width: auto !important;
  }
   
  